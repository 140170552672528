.container-module .container-menu-item {
position:relative;display:flex;justify-content:center
}

.container-module .container-menu-item.menu-item-hidden {
visibility:hidden
}

.container-module .container-menu-item .menu-status-bar {
position:absolute;top:unset;bottom:1px;left:0;width:100%;height:4px;background-color:var(--color-white)
}

.container-module .container-menu-item .container-menu-button {
margin:var(--spacing-xs) 0
}

.container-module .menu-subitems-wrapper {
z-index:var(--z-index-select-main-menu)
}

.container-module .menu-subitems-container {
min-width:200px;overflow:hidden;background-color:var(--color-white);border-radius:6px;box-shadow:0 0 4px 0 rgba(0,0,0,.5)
}

.container-module .menu-subitems-container-dynamic {
min-width:max-content
}

