.container-module .breadcrumbs-hover-menu {
height:calc(34px - var(--spacing-sm)*2);padding:var(--spacing-sm);background-color:color-mix(in srgb, var(--menu-background-color) 95%, var(--color-white));border-radius:var(--spacing-sm);box-shadow:var(--shadow-md)
}

.container-module .breadcrumbs-divider {
line-height:var(--line-height-xs);color:var(--foreground-on-primary)
}

.container-module .breadcrumbs-sub-list-wrapper {
max-height:100dvh
}

