.container-module .container-menu-item-header {
padding:var(--spacing-s) var(--spacing-sm);font-weight:var(--font-semibold);border-top-left-radius:6px;border-top-right-radius:6px
}

.container-module .container-menu-item-header-menu2 {
padding:0 0 var(--spacing-sm) 0
}

.container-module .width-auto {
width:auto
}

