.container-module .context-dropdown {
padding:0 var(--spacing-sm);font:inherit;background-color:var(--context-color, --menu-bar-color);border:2px solid rgba(0,0,0,0);border-radius:var(--field-radius);transition:background-color 250ms
}

.container-module .context-dropdown.context-dropdown-menu-v2 {
padding:var(--spacing-xxs) var(--spacing-sm);background-color:rgba(0,0,0,0);border:0;border-radius:var(--spacing-xs)
}

.container-module .context-dropdown.context-dropdown-menu-v2:hover {
background-color:var(--menu-button-background)
}

.container-module .context-dropdown:focus-visible {
border-color:var(--color-blue500);outline:0
}

.container-module .context-dropdown:hover:not(.context-dropdown-menu-v2) {
background-image:linear-gradient(rgba(0, 0, 0, 0.12) 0 0)
}

.container-module .context-dropdown.context-dropdown-menu-v2-default-color .context-dropdown-content .context-dropdown-icon-container {
background-color:color-mix(in srgb, #ffffff 5%, transparent)
}

.container-module .context-dropdown .context-dropdown-content {
max-width:150px
}

.container-module .context-dropdown .context-dropdown-content .context-dropdown-icon-container {
min-width:var(--field-height);height:var(--field-height);line-height:var(--line-height-lg);background-color:var(--context-color, --background-on-primary);border-radius:var(--radius-circle)
}

.container-module .context-dropdown .context-dropdown-text {
min-width:0
}

.container-module .context-dropdown .context-dropdown-code {
max-width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap
}

.container-module .context-dropdown-active:not(.context-dropdown-menu-v2) {
background-image:linear-gradient(rgba(0, 0, 0, 0.2) 0 0)
}

.container-module .context-dropdown-min-height {
min-height:36px
}

